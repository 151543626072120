




































































import cambioscontrasenasperdidasModule from "@/store/modules/cambioscontrasenasperdidas-module";
import { Component, Vue } from "vue-property-decorator";
import { cambioscontrasenasperdidas } from "@/shared/dtos/cambioscontrasenasperdidas";
import { UtilsString } from "@/utils/utils-string";
import { RouterNames } from "@/router/routernames";
@Component({ $_veeValidate: { validator: "new" } })
export default class pwdlostConfirm extends Vue {
  public type_pass: string = "password";
  private contrasena: string = "";
  private rptcontrasena: string = "";
  private objpwslost = new cambioscontrasenasperdidas();
  private submit() {
    this.$validator.validateAll().then(allOk => {
      if (allOk) {
        this.objpwslost.nueva_contra = this.contrasena;
        this.objpwslost.token_generado = UtilsString.ValueOf(
          this.$route.params.token
        );
        cambioscontrasenasperdidasModule
          .validarcambiodecontrasena(this.objpwslost)
          .then(() => this.$router.push({ name: RouterNames.Login }));
      }
    });
  }
  public showhide() {
    if (this.type_pass === "text") {
      this.type_pass = "password";
    } else {
      this.type_pass = "text";
    }
  }
}
